import { Route } from '@angular/router';
import { HomeComponent } from './home/component';

export const ROUTES: Route[] = [
    {
        path: 'research',
        loadChildren: () => import('./research/routes'),
    }, {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
];
