<header>
    <nav class="navbar navbar-expand-md navbar-toggleable-md text-bg-primary">
        <div class="container-fluid mt-0">
            <a class="navbar-brand"
               routerLink="/">
                <img alt="eduCACE"
                     src="/assets/img/ec-white-50.png" />
            </a>

            <div class="fw-bold fs-4 text-white">eduCACE</div>

            <button aria-label="Toggle navigation"
                    class="navbar-toggler"
                    data-target=".navbar-collapse"
                    data-toggle="collapse"
                    type="button"
                    [attr.aria-expanded]="isExpanded"
                    (click)="toggle()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse d-md-inline-flex justify-content-end"
                 [ngClass]="{ show: isExpanded }">
                <ul class="navbar-nav flex-grow">
                    <li class="nav-item dropdown">
                        <button aria-expanded="false"
                                aria-haspopup="true"
                                class="btn btn-primary dropdown-toggle px-2"
                                data-bs-toggle="dropdown"
                                type="button">
                            <i class="bi-sun"
                               [hidden]="storedTheme !== 'light'"></i>
                            <i class="bi-moon-stars"
                               [hidden]="storedTheme !== 'dark'"></i>
                            <i class="bi-circle-half"
                               [hidden]="storedTheme !== 'auto'"></i>
                            <span class="d-sm-none ms-2">Toggle Theme</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                                <button class="dropdown-item"
                                        type="button"
                                        [ngClass]="{'active-theme': storedTheme === 'light'}"
                                        (click)="setTheme('light')">
                                    <i class="bi-sun me-1"></i>
                                    Light
                                    <i class="bi-check-lg ms-1"
                                       [hidden]="storedTheme !== 'light'"></i>
                                </button>
                            </li>

                            <li>
                                <button class="dropdown-item"
                                        type="button"
                                        [ngClass]="{'active-theme': storedTheme === 'dark'}"
                                        (click)="setTheme('dark')">
                                    <i class="bi-moon-stars me-1"></i>
                                    Dark
                                    <i class="bi-check-lg ms-1"
                                       [hidden]="storedTheme !== 'dark'"></i>
                                </button>
                            </li>

                            <li>
                                <button class="dropdown-item"
                                        type="button"
                                        [ngClass]="{'active-theme': storedTheme === 'auto'}"
                                        (click)="setTheme('auto')">
                                    <i class="bi-circle-half me-1"></i>
                                    Auto
                                    <i class="bi-check-lg ms-1"
                                       [hidden]="storedTheme !== 'auto'"></i>
                                </button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
